import { Link } from "gatsby"
import * as React from "react"
import Logo from "../images/svg/logo.svg"

const MenuList = ({ onClick }) => {
  const buttonClassName =
    "cursor-pointer active:text-white transition-colors ease-in-out duration-150 hover:text-secondary h-full w-full flex items-center justify-center"
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-4 w-full max-w-[1000px] mx-auto text-center items-center">
      <Link
        to="/"
        className={buttonClassName}
        activeClassName="text-secondary"
        onClick={onClick}
      >
        View Menu
      </Link>
      <Link
        to="/about"
        className={buttonClassName}
        activeClassName="text-secondary"
        onClick={onClick}
      >
        About Us
      </Link>
      <div className="hidden md:flex items-center justify-center">
        <Logo className="w-full fill-white" />
      </div>
      <Link
        to="/contact"
        className={buttonClassName}
        activeClassName="text-secondary"
        onClick={onClick}
      >
        Contact Us
      </Link>
      <Link
        to="/gallery"
        className={buttonClassName}
        activeClassName="text-secondary"
        onClick={onClick}
      >
        Gallery
      </Link>
    </div>
  )
}

export default MenuList
