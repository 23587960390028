import {
  faBars,
  faInfoCircle,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import cx from "classnames"
import { navigate } from "gatsby"
import * as React from "react"
import { Sticky } from "react-sticky"

import { StoreContext } from "../context/store-context"
import LogoTextInitials from "../images/svg/logo-initials.svg"
import Logo from "../images/svg/logo.svg"
import Cart from "./cart"
import MenuList from "./menu-list"

const Nav = () => {
  const {
    loading,
    checkout,
    toggleMobileNav,
    mobileNavOpen,
    onCartOpenChange,
    cartOpen,
    isMobile,
  } = React.useContext(StoreContext)

  return (
    <Sticky topOffset={100}>
      {({ style, isSticky }) => (
        <nav
          id="top-nav-bar"
          style={{ ...style }}
          className={cx("text-white flex z-30 uppercase text-md w-full", {
            "shadow-lg bg-black":
              isSticky || mobileNavOpen || (isMobile && cartOpen),
          })}
        >
          <div className="flex flex-1 py-3">
            <div className="md:hidden">
              <button
                onClick={() => toggleMobileNav()}
                className="text-center h-full text-primary flex items-center justify-center p-1 rounded-md ml-2 cursor-pointer mr-2"
              >
                <FontAwesomeIcon
                  fixedWidth
                  size="2xl"
                  icon={mobileNavOpen ? faTimes : faBars}
                />
              </button>
            </div>
            {isSticky && (
              <div className="gap-5 items-center mx-4 hidden lg:flex">
                <a
                  href="https://www.just-eat.co.uk/restaurants-royalwatankashmiri-b29/menu"
                  target="_blank"
                  aria-label="Uber"
                  className="group transition-colors ease-in-out duration-150"
                >
                  <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    role="img"
                    className="w-8 h-8 opacity-60 fill-white group-hover:fill-secondary group-hover:opacity-100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.196.232a1.376 1.376 0 0 1 1.528 0 33.157 33.157 0 0 1 3.384 2.438s.293.203.301-.14a5.367 5.367 0 0 1 .079-1.329.606.606 0 0 1 .562-.39s1.329.066 2.173.179c.377.05.671.352.711.73 0 0 .543 3.62.665 4.925 0 0 .105.664 1.067 1.79 0 0 1.953 2.735 2.18 3.259 0 0 .454.946-.523 1.074 0 0-1.783.18-1.955.22a.446.446 0 0 0-.39.484s-.094 6.296-.555 9.32c0 0-.121 1.2-.782 1.173 0 0-1.833-.059-2.259-.047 0 0-.183 0-.156-.246 0 0 .934-9.817.301-14.78 0 0-.028-.64-.516-.782 0 0-.445-.18-.871.391a15.574 15.574 0 0 0-2.9 8.86s-.05 1.563.188 1.953c0 0 .148.274.907.336l.96.13s.176 0 .16.233c0 0-.218 2.88-.28 3.393a1.018 1.018 0 0 1-.071.34s-.035.098-.336.086c0 0-4.236-.03-4.713 0 0 0-.2 0-.242-.105-.043-.106-.294-3.717-.286-4.229a.255.255 0 0 1 .149-.25 2.548 2.548 0 0 0 1.172-1.871c.052-.548.06-1.098.024-1.646 0 0 .156-5.522.195-6.41 0 0 .031-.3-.36-.355a.364.364 0 0 0-.437.27v.03c0 .032-.274 3.643-.223 5.081 0 0 .094.942-.558.961 0 0-.634.095-.665-.69 0 0 .047-3.542.203-5.292a.39.39 0 0 0-.348-.391.39.39 0 0 0-.437.316.065.065 0 0 0 0 .031s-.274 3.39-.223 5.179c0 0 .078.868-.614.836 0 0-.578.066-.61-.704 0 0 .157-4.85.2-5.224A.39.39 0 0 0 6.647 9h-.039a.391.391 0 0 0-.418.325.167.167 0 0 0 0 .035s-.258 5.8-.223 7.503c0 0-.023 1.751 1.27 2.462 0 0 .192.11.196.277 0 0 .145 3.076.277 4.069 0 0 .047.238-.164.238L4.291 24a.67.67 0 0 1-.665-.633 72.876 72.876 0 0 1-.601-9.829.5.5 0 0 0-.391-.535S.969 12.85.566 12.749a.692.692 0 0 1-.422-1.02A33.497 33.497 0 0 1 11.197.232z" />
                  </svg>
                </a>
                <a
                  href="https://www.ubereats.com/gb/store/royal-watan-tandoori-restaurant/W1j49xR-Rl2SQAQza5QxzQ"
                  target="_blank"
                  aria-label="Uber"
                  className="group transition-colors ease-in-out duration-150"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-label="Uber"
                    role="img"
                    viewBox="0 0 512 512"
                    className="w-8 h-8 opacity-60 fill-white group-hover:fill-secondary group-hover:opacity-100"
                  >
                    <rect width="512" height="512" rx="15%" />
                    <path
                      d="M119.8,303.6c17.6,0,31.3-13.6,31.3-33.8V191.3h19.1V318.6H151.3V306.8a45.9,45.9,0,0,1-33.6,14c-27.3,0-48.2-19.8-48.2-49.8V191.4H88.6v78.5c0,20.5,13.4,33.7,31.2,33.7m64.6-112.3h18.4v46.4a46.11,46.11,0,0,1,32.9-13.8,48.45,48.45,0,0,1,0,96.9A46.52,46.52,0,0,1,202.6,307v11.6H184.4V191.3Zm50,113.2a32.2,32.2,0,1,0-32-32.4v.2a32,32,0,0,0,31.8,32.2h.2M339.3,224c26.7,0,46.4,20.5,46.4,48.2v6H310.3A31.09,31.09,0,0,0,341,304.6c10.7,0,19.8-4.4,26.7-13.6l13.3,9.8c-9.3,12.4-23.1,19.8-40,19.8-27.8,0-49.3-20.7-49.3-48.4-.1-26.2,20.5-48.2,47.6-48.2m-28.8,39.6H367c-3.1-14.2-14.5-23.6-28.2-23.6-13.5,0-25,9.5-28.3,23.6m124.4-21.4c-12,0-20.7,9.3-20.7,23.6v52.7H395.8V225.8H414v11.5c4.5-7.5,12-12.2,22.2-12.2h6.4v17.1Z"
                      className="fill-black"
                    />
                  </svg>
                </a>
                <a
                  href="https://deliveroo.co.uk/menu/Birmingham/moseley/royal-watan-kashmiri-restaurant-602-604-pershore-road?geohash=gcqdkvq4ud13"
                  target="_blank"
                  className="group transition-colors ease-in-out duration-150"
                >
                  <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    role="img"
                    className="w-8 h-8 opacity-60 fill-white group-hover:fill-secondary group-hover:opacity-100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16.861 0l-1.127 10.584L13.81 1.66 7.777 2.926l1.924 8.922-8.695 1.822 1.535 7.127L17.832 24l3.498-7.744L22.994.636 16.861 0zM11.39 13.61a.755.755 0 01.322.066c.208.093.56.29.63.592.103.434.004.799-.312 1.084v.002c-.315.284-.732.258-1.174.113-.441-.145-.637-.672-.47-1.309.124-.473.71-.544 1.004-.549zm4.142.548c.447-.012.832.186 1.05.543.217.357.107.75-.122 1.143h-.002c-.229.392-.83.445-1.422.16-.399-.193-.397-.684-.353-.983a.922.922 0 01.193-.447c.142-.177.381-.408.656-.416Z" />
                  </svg>
                </a>
              </div>
            )}
            {/* <LogoTextInitials className="hidden md:block w-[40px] ml-0 md:ml-5 md:w-[80px] fill-primary" /> */}
            {/* <Logo className="block md:hidden w-[140px] ml-0 fill-primary" /> */}
          </div>
          <div className="block md:hidden p-2">
            <Logo className="block md:hidden w-[140px] ml-0 fill-primary" />
          </div>
          <div className="hidden md:flex flex-[5] p-5 items-center justify-center">
            <MenuList />
          </div>
          <div className="flex-1 flex justify-end">
            <DropdownMenu.Root
              open={cartOpen}
              onOpenChange={onCartOpenChange}
              className="felx items-center"
            >
              <DropdownMenu.Trigger className="relative flex items-center text-2xl h-full px-3 radix-state-open:bg-offwhite text-secondary">
                <FontAwesomeIcon icon={faShoppingCart} />
                {checkout.lineItems.length > 0 && (
                  <div
                    className={cx(
                      "absolute top-[12px] right-[5px] w-4 h-4 text-center rounded-full leading-none flex items-center justify-center text-[10px]",
                      {
                        "text-white bg-black": cartOpen,
                        "text-black bg-primary": !cartOpen,
                      }
                    )}
                  >
                    {checkout.lineItems.length}
                  </div>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal className="relative">
                <DropdownMenu.Content
                  side="bottom"
                  align="end"
                  sideOffset={0}
                  alignOffset={0}
                  className="bg-offwhite rounded-bl-md lg:w-[400px] lg:h-auto w-screen h-[calc(100dvh-65px)] overflow-hidden shadow-2xl z-30 flex flex-col"
                >
                  <DropdownMenu.Label className="text-black text-xl p-3">
                    Your Cart
                  </DropdownMenu.Label>
                  {!checkout.lineItems.length && (
                    <div className="p-3">
                      <h5 className="bg-grey-light text-grey text-center p-5">
                        Your Cart is empty
                      </h5>
                    </div>
                  )}

                  <>
                    <Cart />
                    <div className="flex flex-col gap-2 p-4 shadow-lg shadow-black">
                      {checkout?.discountApplications?.length > 0 && (
                        <div className="grid grid-cols-2 text-sm">
                          <h5>Subtotal:</h5>
                          <div className="text-right">
                            {Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "GBP",
                            }).format(checkout.lineItemsSubtotalPrice?.amount)}
                          </div>
                        </div>
                      )}
                      {checkout?.discountApplications?.map((d, i) => (
                        <div key={i} className="grid grid-cols-[auto,auto]">
                          <div className="text-xs">
                            <span className="px-1 rounded-sm">{d.title}</span>
                          </div>
                          <div className="text-right text-xs text-green">
                            -
                            {Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "GBP",
                            }).format(
                              (d.value.percentage / 100) *
                                checkout.lineItemsSubtotalPrice?.amount
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="grid grid-cols-2 text-lg">
                        <h4 className="uppercase text-black">Total:</h4>
                        <div className="text-right">
                          {Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                          }).format(checkout.totalPriceV2?.amount)}
                        </div>
                      </div>
                      {checkout.totalPriceV2?.amount < 30 && (
                        <div className="text-orange text-sm">
                          {" "}
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="mr-1"
                          />
                          Min order £30 for free local delivery
                        </div>
                      )}
                      {checkout.lineItems.length > 0 && (
                        <a
                          disabled={loading || checkout.lineItems.length === 0}
                          href={checkout.webUrl}
                          target="_self"
                          className="uppercase bg-secondary p-3 w-full rounded-md active:outline text-center"
                        >
                          Checkout
                        </a>
                      )}
                      <DropdownMenu.Item className="lg:hidden uppercase bg-grey p-3 w-full rounded-md text-white active:outline text-center cursor-pointer">
                        Back To Menu
                      </DropdownMenu.Item>
                    </div>
                  </>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        </nav>
      )}
    </Sticky>
  )
}

export default Nav
