import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Form from "@radix-ui/react-form"
import { Link } from "gatsby"
import * as React from "react"
import { useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import FoodHygine from "../images/svg/food-hygine-rating.svg"
import Halal from "../images/svg/halal.svg"

import Logo from "../images/svg/logo.svg"

const socials = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/royal_watan",
    icon: faInstagram,
  },
  {
    title: "Facebook",
    link: "https://www.facebook.com/royalwatan",
    icon: faFacebookF,
  },
  {
    title: "Twitter",
    link: "https://twitter.com/royal_watan",
    icon: faTwitter,
  },
]
const information = [
  { title: "About Us", link: "/about" },
  { title: "Allergens & Intolerances", link: "/about" },
  { title: "Drinks Policy", link: "/about" },
]

const Footer = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)

  async function handleSubmit(event) {
    const data = Object.fromEntries(new FormData(event.currentTarget))
    event.preventDefault()
    setIsSubscribed(true)

    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("userSubscribed", "true")
      setIsSubscribed(true)
      return
    }
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    }).catch(error => alert(error))
  }
  const renderSectionTitle = title => (
    <h3 className="text-xl uppercase text-secondary font-bold mb-4">{title}</h3>
  )
  return (
    <div className="bg-black text-white grid grid-cols-1 lg:grid-cols-4 items-start justify-center gap-10 h-full p-10 [&>div]">
      <div className="grid gap-4">
        {/* <Logo className="w-[200px] fill-white mb-6 hidden lg:block" /> */}
        <div className="w-24">
          <Halal />
        </div>
        <div className=" w-56">
          <FoodHygine />
        </div>
      </div>
      <div className="text-left">
        {renderSectionTitle("Infomration")}
        <ul className="text-lg grid gap-4">
          {information.map((s, i) => (
            <li key={i} className="">
              <Link to={s.link} className="">
                {s.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="text-left">
        {renderSectionTitle("Socials")}
        <ul className="text-lg grid gap-4">
          {socials.map((s, i) => (
            <li key={i} className="">
              <OutboundLink href={s.link} target="_blank" className=" flex">
                <FontAwesomeIcon
                  icon={s.icon}
                  fixedWidth
                  className="flex-0 mr-2 mt-1"
                />
                {s.title}
              </OutboundLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="text-left">
        {renderSectionTitle("Opening Hours")}
        <ul className="text-sm grid grid-cols-2 gap-4">
          <li className="font-bold">Monday</li>
          <li className="">Closed</li>
          <li className="font-bold">TUES - THURS:</li>
          <li className="">17:00 to 00:00</li>
          <li className="font-bold">FRI - SUN:</li>
          <li className="">17:00 to 00:00</li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
